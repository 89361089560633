import { PlasFacet } from "@biblioteksentralen/bmdb-search-client";
import { SearchCategory } from "./SearchContext";

export const workSearchCategories = ["book", "videogame", "movie", "music"] as const;
export type WorkSearchCategory = (typeof workSearchCategories)[number];

export const allSearchFacets = [
  "plas.language",
  "bmdb.format",
  "plas.audience.ageGroup",
  "plas.audience.ageRange",
  "plas.forms",
  "plas.genres",
  "bmdb.medium",
  "bmdb.instrumentation",
  "bmdb.isFiction",
] as const satisfies PlasFacet["type"][];

export type WorkSearchFacet = (typeof allSearchFacets)[number];

export const isWorkSearchFacet = (facetType: PlasFacet["type"]): facetType is WorkSearchFacet =>
  !!allSearchFacets.find((searchFacet) => searchFacet === facetType);

const searchFacetsForBooks = [
  "plas.language",
  "bmdb.format",
  "plas.audience.ageGroup",
  "plas.audience.ageRange",
  "plas.genres",
  "plas.forms",
  "bmdb.isFiction",
] as const satisfies WorkSearchFacet[];

const searchFacetsForVideoGames = [
  "plas.language",
  "bmdb.medium",
  "plas.audience.ageGroup",
  "plas.audience.ageRange",
] as const satisfies WorkSearchFacet[];

const searchFacetsForMovies = [
  "plas.language",
  "bmdb.medium",
  "plas.genres",
  "plas.audience.ageGroup",
  "plas.audience.ageRange",
] as const satisfies WorkSearchFacet[];

const searchFacetsForMusic = [
  "plas.language",
  "bmdb.medium",
  "plas.genres",
  "bmdb.instrumentation",
] as const satisfies WorkSearchFacet[];

export const getCurrentSearchFacets = (category: string | undefined) => {
  if (!isSearchCategory(category)) return [];
  if (category === "book") return searchFacetsForBooks;
  if (category === "videogame") return searchFacetsForVideoGames;
  if (category === "movie") return searchFacetsForMovies;
  if (category === "music") return searchFacetsForMusic;
  return [];
};

const isSearchCategory = (category: string | undefined): category is SearchCategory =>
  !!workSearchCategories.find((workSearchCategory) => workSearchCategory === category);
